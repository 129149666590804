import React from 'react';
import FrontpageLink from '@components/FrontpageLink';
import { css } from '@emotion/react';
import BackgroundImage from 'gatsby-background-image';
import { snakeCase } from 'lodash';

export default function CaseStudies({ data }) {
  return (
    <div
      className="container"
      css={css`
        padding-top: 0rem;
        padding-bottom: 4rem;
      `}
    >
      <h1
        className="text-center mb-5"
        css={css`
            font-size: 2.6rem;
            font-weight: 300;
        `}
        dangerouslySetInnerHTML={{ __html: data.title }}
      />
      <div className="row justify-content-between">
        {/* food_symbol: "kg"
food_value: "3333"
label: "Monthly Savings"
money_symbol: "$"
money_value: "4000"
water_symbol: "l"
water_value: "3454" */}
        {data.testimonials.map((item, i) => (
          <div className="col-12 col-md-4 col-lg-3 my-5" key={`case-study-${i}`}>
            <div
              className="card card-body shadow-3d"
              css={css`
                height: 100%;
                justify-content: space-evenly;
                padding: 1rem;
              `}
            >
              <BackgroundImage
                fluid={item.imageAlt.localFile.sharp.fluid}
                css={css`
                  &::after,
                  &::before {
                    background-position: center center;
                    background-size: auto auto;
                  }
                `}
                style={{ backgroundSize: '', backgroundPosition: '' }}
              >
                <div
                  className="logo-image"
                  css={css`
                    height: 4rem;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                  `}
                />
              </BackgroundImage>
              <div
                className="borderline"
                css={css`
                  margin-top: 1rem;
                  border-bottom: 4px solid var(--dark);
                `}
              />
              <div className="text py-3">
                <p>{item.text.substring(0, 220) + ' [...]'}</p>
              </div>
              <FrontpageLink
                url={item.link.linkUrl}
                className={'btn btn-primary btn-lg'}
                css={css`
                    color: #ffffff;
                    background-color: var(--primary);
                    padding: 0.2em 1em;
                    border-radius: 0.25rem;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 150px;
                    margin: 0 auto 1rem auto;
                    &:hover {
                      text-decoration: none;
                      color: #fff;
                    }
                  `}
                text={item.link.linkText}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}