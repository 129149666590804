import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { getFirstBlock, getNamedBlocks } from '../lib/blocks';
import Header from '@components/Header';
import { css } from '@emotion/react';
import SkyBg from '@images/sky.svg';
// import GreenBottom from '@images/green-bottom.png';
// import GreenMid from '@images/green-mid.png';
// import GreenBg from '@images/green-2-bg.png';
import Hero from '@components/Hero';
import VideoSection from '@components/VideoSection';
import FeaturesSection from '@components/FeaturesSection';
import ProductsSection from '@components/ProductsSection';
import BestKindSection from '@components/BestKindSection';
import Notification from '@components/Notification';
import Calculator from '@components/Calculator';
import CaseStudies from '@components/CaseStudies';
import Contact from '@components/Contact';
import Footer from '@components/Footer';
import Page, { getPageElements } from '@components/Page';
import BackgroundImage from 'gatsby-background-image';

function Index({
  data: {
    GreenTop,
    GreenBottom,
    GreenMid,
    GreenBg,
    customPage,
    testimonialsPage,
    videosPage,
    notificationPage,
    dots,
    page
  },
  pageContext: {
    language, refs, layouts, configuration
  }}) {
  const {
    CustomPage_Block_Block_CalculatorSection: calculator,
    CustomPage_Block_Block_CaseStudiesSection: casestudy,
    CustomPage_Block_Block_ContactUsSection: contact,
    CustomPage_Block_Block_FeaturesSection: features,
    CustomPage_Block_Block_HeroSection: hero,
    CustomPage_Block_Block_ProductSection: product,
    CustomPage_Block_Block_VideoSection: video
  } = getNamedBlocks(customPage);
  const elements = getPageElements(page);
  const notificationBlock = getFirstBlock(notificationPage);
  const testimonialsBlock = getFirstBlock(testimonialsPage);
  const videosBlock = getFirstBlock(videosPage);
  return (
    <Page>
      <Notification notification={notificationBlock} />
      <div
        css={css`
          background: url(${SkyBg});
          background-size: cover;
          background-repeat: no-repeat;
        `}
      >
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
        <Hero
          data={hero}
          GreenTop={GreenTop}
        />
      </div>
      <BackgroundImage
        fluid={GreenMid.sharp.fluid}
      >
        {/* <div
        css={css`
          position: relative;
          margin-top: -1px;
          margin-bottom: -1px;
          background: url(${GreenMid});
          background-size: 100% 100%;
          background-repeat: no-repeat;
        `}
      > */}
        <VideoSection data={video} videos={videosBlock.videos} />
        <Calculator data={calculator} />
      </BackgroundImage>
      <BackgroundImage
        fluid={GreenBottom.sharp.fluid}
        css={css`
          z-index: 1;
          width: 100%;
          padding-bottom: calc(0.093879668049 * 100%);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        `}
      />
      {/* <div
        css={css`
          width: 100%;
          padding-bottom: calc(0.093879668049 * 100%);
          background: url(${GreenBottom});
          background-repeat: no-repeat;
          background-size: 100% 100%;
        `}
      /> */}
      <FeaturesSection
        data={features}
        dots={dots}
      />
      <ProductsSection data={product} />
      <BestKindSection elements={elements} />
      <BackgroundImage
        fluid={GreenBg.sharp.fluid}
        css={css`
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left top;
      padding-bottom: 3rem;
    `}
      >
        {/* <div
        css={css`
          background: url(${GreenBg});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: left top;
          padding-bottom: 3rem;
        `}
      > */}
        {' '}
        <CaseStudies data={testimonialsBlock} />
        <Contact data={contact} language={language} typeforms={configuration.typeforms} />
      </BackgroundImage>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export default Index;

export const query = graphql`
  query($id: String, $indexId: String, $testimonialsId: String, $videosId: String, $notificationId: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    testimonialsPage: wpCustomPage(id: { eq: $testimonialsId }) {
      block {
        block {
          ... on WpCustomPage_Block_Block_TestimonialsSection {
            title
            testimonials {
              name
              text
              link {
                linkUrl
                linkText
              }
              imageAlt {
                localFile {
                  sharp: childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    notificationPage: wpCustomPage(id: { eq: $notificationId }) {
      block {
        block {
          ... on WpCustomPage_Block_Block_NotificationSection {
            title
            body
            image {
              localFile {
                sharp: childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            link {
              linkUrl
              linkText
            }
          }
        }
      }
    }
    videosPage: wpCustomPage(id: { eq: $videosId }) {
      block {
        block {
          ... on WpCustomPage_Block_Block_VideosSection {
            videos {
              title
              videoId
              description
              type
            }
          }
        }
      }
    }
    dots: file(relativePath: { eq: "dotted-bg.png" }) {
     sharp: childImageSharp {
       fluid {
         ...GatsbyImageSharpFluid
        }
      }
    }
    GreenTop: file(relativePath: { eq: "green-top.png" }) {
     sharp: childImageSharp {
       fluid {
         ...GatsbyImageSharpFluid
        }
      }
    }
    GreenBottom: file(relativePath: { eq: "green-bottom.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    GreenMid: file(relativePath: { eq: "green-mid.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    GreenBg: file(relativePath: { eq: "green-2-bg.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customPage: wpCustomPage(id: { eq: $indexId }) {
      id
      language {
        code
      }
      block {
        block {
          ... on WpCustomPage_Block_Block_HeroSection {
            fieldGroupName
            title: heroTitle
            text: heroText
            link {
              text: linkText
              url: linkUrl
              style: linkStyle
            }
          }
          ... on WpCustomPage_Block_Block_VideoSection {
            fieldGroupName
            title: videoTeaserTitle
		        wistiaId: videoTeaserWistiaId
          }
          ... on WpCustomPage_Block_Block_CalculatorSection {
            fieldGroupName
            title: calculatorTitle
            label: calculatorLabel
            savingsHeader: calculatorSavingsHeader
            savingsMoneyLabel: calculatorSavingsMoneyLabel
            savingsFoodLabel: calculatorSavingsFoodLabel
            savingsWaterLabel: calculatorSavingsWaterLabel
            pinpoints: calculatorPinpoints {
              pinpoint: revenuePinpoint
              savedMoney
              savedFood
              savedWater
            }
          }
          ... on WpCustomPage_Block_Block_FeaturesSection {
            fieldGroupName
            featuresTitle
            featuresList {
              entry {
                entryTitle
              }
            }
            link {
              linkText
              linkUrl
              linkStyle
            }
          }
          ... on WpCustomPage_Block_Block_ProductSection {
            fieldGroupName
            productsTitle
            products {
              productTitle
              productDescription
            }
            link {
              text: linkText
              url: linkUrl
            }
          }
          ... on WpCustomPage_Block_Block_CaseStudiesSection {
            fieldGroupName
            title: caseStudiesTitle
            caseStudies {
              logo: caseStudyLogo {
                localFile {
                  sharp: childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                      presentationWidth
                    }
                  }
                }
              }
              link {
                linkText
                linkUrl
              }
              label: caseStudyLabel
              moneyValue: caseStudyMoneyValue
              moneySymbol: caseStudyMoneySymbol
              foodValue: caseStudyFoodValue
              foodSymbol: caseStudyFoodSymbol
              waterValue: caseStudyWaterValue
              waterSymbol: caseStudyWaterSymbol
            }
          }
          ... on WpCustomPage_Block_Block_ContactUsSection {
            fieldGroupName
            contactUsTitle
            contactUsText
            link {
              style: linkStyle
              text: linkText
              url: linkUrl
            }
          }
        }
      }
    }
  }
`;