import React from 'react';
import { css } from '@emotion/react';
import GatsbyImage from 'gatsby-image';

export default function BestKindSection({ elements }) {

  return (
    <div>
      <div
        className="container text-center "
        css={css`
          padding-bottom: 6rem;
        `}
      >
        <div
          css={css`
            p {
              font-weight: 300;
              font-size: 2.6rem;
              margin-bottom: 3rem;

              @media (max-width: 767px) {
                margin-bottom: 2rem;
              }
            }
          `}

        >
          <p>{elements.labelBestKind}</p>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
              <a className="w-100" href="http://startupeuropeawards.eu/">
                <GatsbyImage
                  fluid={elements.badgeStartup.localFile.sharp.fluid}
                />
              </a>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="h-100 w-100 d-flex justify-content-center align-items-center" dangerouslySetInnerHTML={{ __html: elements.badgeGoodFirms }} />
          </div>
          <div className="col-sm-4">
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
              <a style={{ width: '50%' }} href="https://gastro.bsdpoland.pl/">
                <GatsbyImage
                  fluid={elements.badgeGastroMeet.localFile.sharp.fluid}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}