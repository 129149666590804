import React from 'react';
import { css } from '@emotion/react';
import ResponsiveVideo from '@components/ui/ResponsiveVideo';
import { find } from 'lodash';

export default function VideoSection({ data, videos }) {
  const restaurantVideo = find(videos, { type: 'explainer_restaurant' });
  return (
    <div className="d-flex justify-content-center" css={css``}>
      <div
        className="mx-auto"
        css={css`
          width: 90vw;
          max-width: 700px;
          padding-top: 3rem;
          padding-bottom: 5rem;
          background-color: #ffffff;
          box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.1);
          border-radius: 2rem;
          position: relative;
          margin-top: -12rem;
          z-index: 2000;
          /* @sm */
          @media (min-width: 576px) {
            width: 60vw;
          }
          /* @md */
          @media (min-width: 768px) {
            width: 40vw;
            margin-top: -10rem;
          }
          /* @lg */
          @media (min-width: 992px) {
            margin-top: -18rem;
            width: 50vw;
          }
          /* @xl */
          @media (min-width: 1200px) {
            margin-top: -20rem;
            width: 40vw;
          }
        `}
      >

        <div
          css={css`
              text-align: center;
              margin-top: 0.25rem;
              font-size: 1.25rem;
              padding: 0 1rem;
              text-transform: uppercase;
              font-weight: 300;
            `}
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
        <ResponsiveVideo title={restaurantVideo.title} videoId={restaurantVideo.videoId} />
      </div>
    </div>
  );
}