import React from 'react';
import { css } from '@emotion/react';
import Boat from '@images/boat.svg';
// import GreenTop from '@images/green-top.png';
import BackgroundImage from 'gatsby-background-image';
import FrontpageLink from '@components/FrontpageLink';

export default function Hero({ data, GreenTop }) {
  const { title, text, link } = data;
  return (
    <div
      css={css`
        position: relative;
        padding-top: 2rem;
      `}
    >
      <div className="container">
        {' '}
        <div className="row">
          <div
            className="col-md-6 col-12"
            css={css`
              > * {
                margin-bottom: 1rem;
                /* @md */
                @media (min-width: 768px) {
                  margin-bottom: 1.5rem;
                }
              }
            `}
          >
            <h1
              css={css`
                font-size: 2rem;
                /* @md */
                @media (min-width: 768px) {
                  font-size: 2.8rem;
                }
                color: #fff;
                margin-top: 2rem;
              `}
            >
              {title}
            </h1>
            <div
              css={css`
                & > * {
                  font-size: 1.25rem;
                  /* @md */
                  @media (min-width: 768px) {
                    font-size: 1.5rem;
                  }
                  color: #fff;
                  font-weight: 300;
                }
              `}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <FrontpageLink
              text={link.text}
              url={link.url}
              className="btn btn-primary btn-lg text-white"
              css={css`
                font-size: 1rem;
                /* @md */
                @media (min-width: 768px) {
                  font-size: 1.25rem;
                }
                border-radius: 1.5em;
                text-transform: uppercase;
                padding: 0.5em 1.5em;
              `}
            />
          </div>
          <div
            className="col-md-6 col-12"
            css={css`
              margin-top: 4rem;
              /* @md */
              @media (min-width: 768px) {
                margin-top: 0;
              }
            `}
          >
            <img
              alt="sinking boat"
              src={Boat}
              css={css`
                margin-bottom: 1rem;
                /* @md */
                @media (min-width: 768px) {
                  margin-bottom: 4rem;
                }
                /* @lg */
                @media (min-width: 1200px) {
                  margin-bottom: 8rem;
                }
            `}
            />
          </div>
        </div>
      </div>
      <BackgroundImage
        fluid={GreenTop.sharp.fluid}
        css={css`
        pointer-events: none;
          position: absolute !important;
          left: 0;
          bottom: 0;
          width: 100%;
          padding-bottom: 41%;
        `}
      />
    </div>
  );
}