import React, { useEffect, useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';

export default function Notification({ notification: { title, body, image, link }}) {

  const [isVisible, setVisible] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 4000);
  }, []);

  let notificationClass = 'Notification card card-article-wide flex-md-row no-gutters';
  notificationClass += (isVisible ? ' Notification--show' : '');

  return (
    <div className={notificationClass}>
      <Link to={link.linkUrl} className="col-4">
        <BackgroundImage
          alt="Image"
          className="card-img-top"
          fluid={image.localFile.sharp.fluid}
        />
      </Link>
      <div className="card-body d-flex flex-column justify-content-between col-8 p-4">
        <a href="#" className="Notification-close text-muted" onClick={handleClick}>{'x'}</a>
        <Link to={link.linkUrl}>
          <h4 className="mt-0">{title}</h4>
        </Link>
        <div dangerouslySetInnerHTML={{ __html: body }} />
        <Link to={link.linkUrl} className="btn btn-primary">
          {link.linkText}
        </Link>
      </div>
    </div>
  );
}