import React, { useState, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import MoneyIcon from '@images/money-icon.svg';
import FoodIcon from '@images/food-icon.svg';
import WaterIcon from '@images/water-icon.svg';
import { get } from 'lodash';

const id = 'calculator';

const LabelCSS = css`
  font-weight: 300;
`;
const NumberCSS = css`
  font-weight: bold;
  margin-bottom: 0;
  font-size: 2rem;
  margin-bottom: -0.5rem;
`;

const SavingsValues = {
  PINPOINT: 'pinpoint',
  SAVED_MONEY: 'savedMoney',
  SAVED_FOOD: 'savedFood',
  SAVED_WATER: 'savedWater'
};

export default function Calculator({ data: { pinpoints, title, label, savingsHeader, savingsMoneyLabel, savingsFoodLabel, savingsWaterLabel }}) {
  const [revenue, setRevenue] = useState(10000);

  const handleRevenueChange = (e) => setRevenue(e.target.value);

  const calculateCosts = useCallback((args, revenue) => {
    let value = revenue / get(pinpoints, `[1].${SavingsValues.PINPOINT}`, 0) - get(pinpoints, `[0].${SavingsValues.PINPOINT}`, 0);
    return (value * (get(pinpoints, `[1].${args}`, 0) - get(pinpoints, `[0].${args}`, 0))).toFixed(2);
  }, []);

  const savedMoney = useMemo(() => Math.round(calculateCosts(SavingsValues.SAVED_MONEY, revenue)), [revenue]);
  const savedFood = useMemo(() => Math.round(calculateCosts(SavingsValues.SAVED_FOOD, revenue)), [revenue]);
  const savedWater = useMemo(() => Math.round(calculateCosts(SavingsValues.SAVED_WATER, revenue)), [revenue]);

  return (
    <div id={id} className="container py-5">
      <div
        className="text-center"
        css={css`
          font-size: 2rem;
        `}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div>
        <div
          className="text-center"
          css={css`
            text-transform: uppercase;
            color: #ffffff;
            font-size: 1rem;
            font-weight: bold;
            letter-spacing: 1.16px;
          `}
        >
          <label htmlFor="revenue">
            {label}
          </label>
        </div>
        <div
          css={css`
            text-align: center;
            font-size: 2rem;
            font-weight: bold;
          `}
        >
          {revenue}
        </div>
        <input
          type="range"
          min="10000"
          max="200000"
          id="revenue"
          step="1000"
          className="custom-range my-4 mx-auto"
          css={css`
            max-width: 700px;
            display: block;
          `}
          onChange={handleRevenueChange}
        />
      </div>
      <div
        className="my-4 text-center"
        css={css`
          font-size: 1.5rem;
          font-weight: 300;
        `}
      >
        {savingsHeader}
      </div>
      <div className="row py-4 mb-3">
        <div className="text-center col-12 col-md-4 mb-5 mb-md-0">
          {/* TODO: alt i18n */}
          <img
            alt="money icon"
            src={MoneyIcon}
            className="mb-3"
            css={css`
              width: 100px;
            `}
          />
          <div css={NumberCSS}>
            {savedMoney}
          </div>
          <div css={LabelCSS}>{savingsMoneyLabel}</div>
        </div>
        <div className="text-center col-12 col-md-4 mb-5 mb-md-0">
          {/* TODO: alt i18n */}
          <img
            alt="food icon"
            src={FoodIcon}
            className="mb-3"
            css={css`
              width: 100px;
            `}
          />
          <div css={NumberCSS}>{savedFood}</div>
          <div css={LabelCSS}>{savingsFoodLabel}</div>
        </div>
        <div className="text-center col-12 col-md-4 mb-5 mb-md-0">
          {/* TODO: alt i18n */}
          <img
            alt="water icon"
            src={WaterIcon}
            className="mb-3"
            css={css`
              width: 100px;
            `}
          />
          <div css={NumberCSS}>{savedWater}</div>
          <div css={LabelCSS}>{savingsWaterLabel}</div>
        </div>
      </div>
      <div
        css={css`
          color: #282c30;
          text-align: center;
          font-size: 0.7rem;
          span {
            color: #ffffff;
            font-weight: bold;
          }
        `}
      >
        {/* FIXME: Want to know more? See our <span>Advanced Calculator</span>*/}
      </div>
    </div>
  );
}