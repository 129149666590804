import React from 'react';
import { css } from '@emotion/react';
//import Dots from '@images/dotted-bg.png';
import InventoryIcon from '@images/inventory-icon.svg';
import SupplyIcon from '@images/supply-icon.svg';
import WasteIcon from '@images/waste-icon.svg';
import FrontpageLink from '@components/FrontpageLink';
import BackgroundImage from 'gatsby-background-image';
//import GatsbyImage from 'gatsby-image';

export default function FeaturesSection({ data, dots }) {

  const IconsCSS = css`
    width: 130px;
    /* @md */
    @media (min-width: 768px) {
      width: 80px;
    }
    @media (min-width: 992px) {
      width: 130px;
    }
  `;

  return (
    <BackgroundImage
      fluid={dots.sharp.fluid}
      css={css`

          position: relative;
          margin-top: -7%;
          padding-top: 10%;


        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: top;
        padding-bottom: 10%;
        /* @md */
        @media (min-width: 768px) {
          background-size: 100% auto;
        }
      `}
    >
      <div className="container text-center ">
        {' '}
        <h2
          className=""
          css={css`
            font-weight: 300;
            font-size: 2.6rem;
          `}
        >
          {data.featuresTitle}
        </h2>
        <div className="row py-5">
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
            className="col-12 col-md-4 mb-md-0 mb-5 text-center"
          >
            <div
              css={css`
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                justify-content: center;
              `}
            >
              {' '}
              <img css={IconsCSS} src={InventoryIcon} alt="inventory icon" />
            </div>
            <div className="py-3">
              {' '}
              {data.featuresList[0].entry.entryTitle}
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
            className="col-12 col-md-4 mb-md-0 mb-5 text-center"
          >
            <div
              css={css`
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                justify-content: center;
              `}
            >
              {' '}
              <img css={IconsCSS} src={SupplyIcon} alt="supply icon" />
            </div>
            <div className="py-3">
              {data.featuresList[1].entry.entryTitle}
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
            className="col-12 col-md-4 mb-md-0 mb-5 text-center"
          >
            <div
              css={css`
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                justify-content: center;
              `}
            >
              {' '}
              {/* TODO: alt i18? */}
              <img css={IconsCSS} src={WasteIcon} alt="waste icon" />
            </div>
            <div className="py-3">
              {' '}
              {data.featuresList[2].entry.entryTitle}
            </div>
          </div>
          <div css={css`
              display: flex;
              flex-grow: 1;
              justify-content: center;
            `}
          >
            <FrontpageLink
              url={data.link.linkUrl}
              text={data.link.linkText}
              className={'btn btn-primary btn-lg'}
              css={css`
              color: #ffffff;
              background-color: var(--primary);
              padding: 0.2em 1em;
              border-radius: 1.25rem;
              text-transform: uppercase;
              font-weight: 600;
              font-size: 1.5rem;
            `}
            />
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
}