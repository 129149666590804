import React, { Fragment, PureComponent } from 'react';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { getTypeformUrl } from '@lib/urls';

export default class Contact extends PureComponent {
  componentDidMount() {
    const { language, typeforms } = this.props;

    this.openTypeform = () => {
      let typeform = window.typeformEmbed.makePopup(getTypeformUrl({ type: 'contact', typeforms, language }), {
        mode: 'popup',
        hideHeaders: true,
        hideFooter: true
      });
      typeform.open();
    };

    window.document.addEventListener('click', (event) => {
      if (event.target.matches('#aform') || event.target.matches('#abutton')) {
        event.preventDefault();
        this.openTypeform();
      } else if (event.target.matches('#aintercom')) {
        event.preventDefault();
      }
    }, false);
  }

  render() {
    const { data } = this.props;
    return (
      <Fragment>
        <Helmet>
          <script async={true} src="https://embed.typeform.com/embed.js" type="text/javascript"></script>
        </Helmet>
        <div className="container mb-5">
          {' '}
          <div
            className="text-center mb-4"
            css={css`
            p {
              font-size: 2.6rem;
              font-weight: 300;
            }
          `}
            dangerouslySetInnerHTML={{ __html: data.contactUsTitle }}
          />
          <div
            css={css`
            a {
              color: #fff;
            }
          `}
            className="lead text-center mb-5"
            dangerouslySetInnerHTML={{ __html: data.contactUsText }}
          />
          <div className="text-center">
            <button
              id="abutton"
              className="btn btn-dark"
              css={css`
                background-color: #282c30;
                color: #ffffff;
                font-size: 1.25rem;
                font-weight: bold;
                padding: 0.35rem 1.75rem;
                border-radius: 4px;
                &:hover {
                  text-decoration: none;
                  color: #86c445;
                }
              `}
            >
              {data.link.text}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export const query = graphql`
  fragment ContactBlock on WpCustomPage {
    block {
      block {
        ... on WpCustomPage_Block_Block_ContactUsSection {
          contactUsTitle
          contactUsText
          link {
            style: linkStyle
            text: linkText
            url: linkUrl
          }
        }
      }
    }
  }
`;